<template>
  <draggable
    v-model="dashboardSort.section_2"
    class="section-two"
    group="cards-tag"
    :class="isDisable ? 'section-two-in-active' : ''"
    :disabled="isDisable"
    @change="updateOrder"
  >
    <v-card
      v-for="(row, i) in dashboardSort?.section_2"
      :key="i"
      :loading="loading"
    >
      <div
        class="px-2 py-2 Tag_card"
        :style="{ 'background-color': row.card_color_code }"
      >
        <div class="d-flex justify-space-between align-center">
          <label for="">{{ row.card_name }}</label>
          <span>
            <ColorPicker
              v-if="!isDisable"
              v-model="row.card_color_code"
              @change="changeCardColor(row)"
            />
          </span>
        </div>
        <v-divider />
        <span>{{ dashboadData[row?.keys[0]] }}</span>
        <label>
          {{ $t(`units.${row.keys[0]}`) | currencyFormat(authUser) }}
        </label>
      </div>
    </v-card>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    ColorPicker: () => import("@/components/base/ColorPicker.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      //
      selectedCards: "dashboard/getSelectedCards",
    }),
    sectionTwo() {
      return this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  methods: {
    updateOrder() {
      const params = {
        section: "section_2",
        sort: true,
        item: this.dashboardSort.section_2,
        vehicle_tech: this.vehicleTech,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
    changeCardColor(row) {
      const params = {
        section: "section_2",
        user_card_id: row.id,
        card_color_code: row.card_color_code,
      };
      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-two
  user-select: none
  .v-card
    cursor: all-scroll
    transition: 0.7s all ease
    background: #d5f1d5 !important
    &:hover
      box-shadow: 2px 1px 8px #20a390 !important
      transform : scale3d(1.2, 1.3, 1.9)

    label
      font-size: 12px
    hr
      background: green
    span
      font-size: 16px
      font-weight: 600
</style>
